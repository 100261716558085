<template>
  <!-- documents -->


    <!-- la boite de dialogue permettant d'upload un fichier -->
    <b-modal id="my-modal" v-model="modalShow" ref="ajoutDocRef" size="lg"  centered ok-title="Envoyer" title="Ajout d'un document"
             cancel-title="Annuler" @ok="onAddDocument">
      <h3>Sélectionnez un document{{ financeur ? '' : ' et qualifiez son contenu' }}</h3>
      <hbox class="wrap-small">
        <vbox class="vbox field-group same file-chooser">
          <div v-if="!financeur">
            <label>type de fichier</label>
            <b-form-select v-model="typeFichier" :options="options"></b-form-select>
            <br><br>
          </div>
          <label >fichier</label>
          <b-field class="file vbox" type="is-danger" ref="fichier">
            <b-upload v-model="fichier" accept=".pdf,.doc,.jpg,.png,.jpeg,.docx,.xls,.xlsx,.msg,.ods,.bmp,.rtf,.odt,.eml" drag-drop  validationMessage="Format du document non supporté">
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                        icon="upload"
                        size="is-large">444
                    </b-icon>
                  </p>
                  <p>Glissez votre fichier ici ou cliquez pour le sélectionner</p>
                </div>
              </section>
            </b-upload>
            <div class="file-name" v-if="fichier">{{fichier.name}}</div>
          </b-field>
        </vbox>
      </hbox>
    </b-modal>
</template>

<script>
import GipMixin from "./GipMixin";
import kit from "../../kit/kit";

export default {
name: "Documents",
  mixins: [GipMixin],
  props:{
    callback:{
      type: Function,
      required: true
    },
    ownerId:{
      type: String,
      required: true
    },
    financeur: {
      type: Boolean,
      required: true
    }

  },
    data() {
  return {
    typeFichier: this.financeur ? null : 1,
    fichier: null,
    modalShow: false,
    options:[],
  }

},
  mounted() {
  this.getTypeFichier()
  },
  methods: {
    onAddDocument: function (modal){
      modal.preventDefault()
      if(this.fichier){
        this.sendFile()
        this.modalShow = false
      }
    },

    getTypeFichier(){
      kit.call(() => {
        var query = {cclass: 'TypeDocument', json: true};
        kit.services.query(query,
            (set, req) => {

              for(let i = 0; i < set.length; i++){
                set[i].value = set[i]['typeDocumentId'];
                set[i].text = set[i]['type'];
                delete set[i].type;
                delete set[i].typeDocumentId;

              }
                  this.options = set;

              // console.log(this.options)
            },
            () => {
              kit.log.error("erreur getTypeFichier")
            }
        )
      });
    },
    sendFile() {
      var formData = new FormData();
      formData.append("file", this.fichier, this.fichier.name);
      formData.append("upload_file", true);
      formData.append("origine", "SITE")
      if (!this.financeur) formData.append("typeDocumentId", this.typeFichier)
      formData.append("ownerId", this.ownerId)
      formData.append("ownerType", this.financeur ? 'financeur' : 'employeur')
      
      kit.services.service(
        'upload',
        formData, 
        (data) =>  {
          data.type = this.typeFichier
          console.log(data)
          this.fichier = null;
          this.callback(data)
          },
         (err)  => {
            kit.dialogs.error("Une erreur s'est produite")
            kit.log.error("error upload : " + err)
        }
      );
    }
  }
}
</script>
<style scoped>
#my-modal {
  z-index:9500;
}

h3 {
  font-size: 1.5em;
}
</style>